
export default {
    name: "MasonryGallery",
    components: {},
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    computed: {
        mediaListEven() {
            return this.mediaList.filter((a, i) => i % 2 === 0)
        },
        mediaListOdd() {
            return this.mediaList.filter((a, i) => i % 2 === 1)
        },
        mediaList() {
            return this.blok.media
        },
    },
    methods: {
        isVideo(medium) {
            return (
                medium.filename.endsWith(".mp4") ||
                medium.filename.endsWith(".webm") ||
                medium.filename.endsWith(".mov") ||
                medium.filename.endsWith(".avi") ||
                medium.filename.endsWith(".wmv") ||
                medium.filename.endsWith(".flv")
            )
        },
    },
}
